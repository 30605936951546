import { RefObject, useEffect, useId, useRef, useState } from 'react';

import { Swiper } from 'swiper';
import {
  Autoplay as SwiperAutoplay,
  EffectFade as SwiperEffectFade,
  Navigation as SwiperNavigation,
  Pagination as SwiperPagination,
} from 'swiper/modules';

import {
  HeroBlockFragment,
  SliderBlockFragment,
} from '@hultafors/hultafors/types';

import { HeroBlock } from '../HeroBlock/HeroBlock';
import { Icon } from '../Icon/Icon';

import {
  ArrowBtn,
  ArrowNavigator,
  DotBtn,
  DotBtnInner,
  DotNavigator,
  SliderBlockStyled,
  SwiperSlide,
} from './SliderBlock.styled';

export const SliderBlock: React.FC<SliderBlockFragment> = ({
  heroes,
  title,
  arrows,
  slideAnimation,
  dots,
  delay: initialDelay,
}) => {
  const uid = useId();
  const swiper = useRef<Swiper | null>(null);
  const swiperElement = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const slideRef = useRef<HTMLDivElement>(null);

  const SwiperModules = [
    SwiperAutoplay,
    SwiperNavigation,
    SwiperPagination,
    SwiperEffectFade,
  ];
  let animationEffect = 'fade';
  if (slideAnimation) {
    animationEffect = 'slide';
  }

  function initializeSwiper(element: RefObject<HTMLDivElement | null>) {
    if (element.current && !swiper.current) {
      swiper.current = new Swiper(element.current, {
        allowTouchMove: true,
        autoplay: {
          delay: initialDelay,
          disableOnInteraction: false,
        },
        direction: 'horizontal',
        effect: animationEffect,
        fadeEffect: {
          crossFade: true,
        },
        loop: true,
        modules: SwiperModules,
        on: {
          realIndexChange: (target) => {
            setCurrentIndex(target.realIndex);
          },
        },
        pagination: {
          clickable: true,
          el: paginationRef.current,
          type: 'bullets',
        },
        speed: 800,
      });
    }
  }

  useEffect(() => {
    initializeSwiper(swiperElement);
  }, []);

  function heroMapper(hero: HeroBlockFragment, index: number) {
    return (
      <SwiperSlide
        ref={slideRef}
        className="swiper-slide"
        key={`SliderBlock-${uid}-${index}`}
      >
        <HeroBlock {...hero} inSlider={true} />
      </SwiperSlide>
    );
  }

  function paginationDotsMapper(hero: HeroBlockFragment, index: number) {
    function onClick() {
      swiper.current?.slideToLoop(index);
    }
    return (
      <DotBtn
        $dark={hero.darkText}
        onClick={onClick}
        key={`dot-navigator-${index}`}
        aria-label={`Slide navigator ${index}`}
      >
        <DotBtnInner $active={currentIndex === index} />
      </DotBtn>
    );
  }

  function slidePrev() {
    swiper.current?.slidePrev();
  }
  function slideNext() {
    swiper.current?.slideNext();
  }

  return (
    <SliderBlockStyled>
      <div className="swiper" ref={swiperElement}>
        <div className="swiper-wrapper">
          {heroes.map(heroMapper)}
          {dots && (
            <DotNavigator>{heroes.map(paginationDotsMapper)}</DotNavigator>
          )}
        </div>
        {arrows && (
          <ArrowNavigator>
            <ArrowBtn onClick={slidePrev} aria-label="Prev" ref={prevRef}>
              <Icon svg="chevron" width={46} height={46} />
            </ArrowBtn>
            <ArrowBtn onClick={slideNext} aria-label="Next" ref={nextRef}>
              <Icon svg="chevron" width={46} height={46} />
            </ArrowBtn>
          </ArrowNavigator>
        )}
      </div>
    </SliderBlockStyled>
  );
};
